import classNames from "classnames"
import { useState } from "react"

export default function SelectVip({ value, onChange }: { value?: string, onChange?: (a: string) => void }) {
    // const [selected, setSelected] = useState()

    return <div>
        {/* <div>
            选择 VIP 套餐
        </div> */}

        <div className='flex flex-col gap-2'>

            {[{
                name: '半年',
                price: 39,
            },
            {
                name: '一年',
                price: 62,
            }, {
                name: '三年',
                price: 158,
            }].map(i => {
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                return <div key={i.name}
                    className={
                        classNames([
                            'flex flex-row justify-items-center items-center p-2 justify-evenly border-slate-800 border-solid rounded-lg text-lg'
                            , { 'border-slate-200 ': value === i.name }])
                    }

                    style={{
                        border: value === i.name ? "1px solid black" : "1px solid #00000022",
                        color: 'black'
                        // value === i.name ? "black" : "#00000022"
                    }}
                    onClick={() => {
                        onChange?.(i.name)
                    }}
                >
                    <div>{i.name}</div>
                    <div>{i.price}元</div>
                </div>
            })}
        </div>
    </div>
}